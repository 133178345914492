@use 'assets/scss/mixins.scss' as mixins;

@mixin hardwareImage {
    height: 150px;
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    box-sizing: border-box;

    img {
        flex: 1;
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: relative;
        z-index: 2;
    }

    div.product-image-failed {
        flex: 1;
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: relative;
        z-index: 2;
    }

    img.product-image {
        object-fit: fill;
        width: 100%;
        position: relative;
        box-shadow: inset 0 0 2px 2px white;
    }

    div.product-image-failed {
        object-fit: cover;
        width: 100%;
        height: auto;
        position: relative;
        box-shadow: inset 0 0 2px 2px white;
    }

    img::before {
        content: 'No Image Available';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        color: #6b6f70;
        text-align: center;
        padding-top: 22%;
        background: #f2f1f0;
        width: 100%;
    }

    div.product-image-failed {
        color: #6b6f70;
        text-align: center;
        padding-top: 22%;
        background: #f2f1f0;
        width: 100%;
    }
}

.hardware,
.Products {
    h1 {
        margin-bottom: 5px;
    }
    .hardware-content,
    .product-content {
        .search-info ul {
            list-style: none;
            margin: 0 0 20px !important;
            padding: 0 !important;

            li:first-child {
                padding-left: 0;
            }
            li {
                display: inline-block;
                vertical-align: top;
                a {
                    font-size: 0.75em;
                    padding: 0 5px;
                    border-right: 1px solid gray;
                    color: gray;
                }
            }
            li:last-child {
                a {
                    @include mixins.theme('color', 'ui-secondary-color');
                    border: 0;
                }
            }
        }
    }

    .productSearch {
        display: flex;
        align-items: center;

        label {
            font-weight: bold;
            @include mixins.theme('color', 'ui-primary-color');
            display: block;
            margin: 0 !important;
            box-sizing: border-box;
            padding-right: 15px;
        }

        .productSearchField {
            flex: 1;
            display: flex;
            border: 2px solid;
            @include mixins.theme('border-color', 'ui-primary-color');
            border-radius: 5px;

            .searchKeysNInput {
                flex: 1;
                display: flex;
                flex-wrap: wrap;

                .searchKey {
                    @include mixins.theme('background', 'ui-secondary-color');
                    margin: 2px;
                    border-radius: 5px;
                    font-weight: 500;
                    font-size: 0.75em;
                    padding: 5px;
                    color: white;
                    vertical-align: middle;
                    text-align: center;
                    opacity: 0.5;
                    display: flex;
                    align-items: center;
                }

                .searchKey.hasHandler {
                    opacity: 1;
                    padding-right: 2px;

                    div:first-child {
                        flex: 1;
                    }

                    div:last-child {
                        background: white;
                        @include mixins.theme('color', 'ui-secondary-color');
                        width: 10px;
                        height: 10px;
                        border-radius: 10px;
                        text-align: center;
                        line-height: 10px;
                        font-weight: bold;
                        font-size: 12px;
                        margin-left: 5px;
                        cursor: pointer;
                    }
                }

                .searchField {
                    flex: 1;
                    min-width: 165px;
                    border: 0;
                    height: inherit;
                    padding: 0 5px;
                    border-radius: 5px;
                }
            }

            .searchButton {
                padding: 0;
                margin: 0;

                img {
                    width: 25px;
                }
            }
        }
    }

    .hardwareCategories {
        margin-top: 15px;

        .hardwareCategory {
            border-radius: 20px;
            cursor: pointer;

            $animation-duration: 6s;

            @keyframes placeHolderShimmer {
                0% {
                    background-position: 0px 0;
                }

                100% {
                    background-position: 100em 0;
                }
            }

            .animated-background {
                border-radius: 20px;
                margin: 0;
                width: 200px;
                height: 200px;
                animation-duration: $animation-duration;
                animation-fill-mode: forwards;
                animation-iteration-count: infinite;
                animation-name: placeHolderShimmer;
                animation-timing-function: linear;
                background: #fff;
                background: linear-gradient(
                    to right,
                    #f5f5f5 8%,
                    /* lighter grey color */ #eeeeee 18%,
                    /* lighter grey color */ #f5f5f5 33%
                        /* lighter grey color */
                );
                -webkit-backface-visibility: hidden;
            }

            .animated-background-for-image {
                border-radius: 20px;
                margin: 0;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                color: #6b6f70;
                text-align: center;
                height: 140px;
                animation-duration: $animation-duration;
                animation-fill-mode: forwards;
                animation-iteration-count: infinite;
                animation-name: placeHolderShimmer;
                animation-timing-function: linear;
                background: #fff;
                background: linear-gradient(
                    to right,
                    #f5f5f5 8%,
                    /* lighter grey color */ #eeeeee 18%,
                    /* lighter grey color */ #f5f5f5 33%
                        /* lighter grey color */
                );
                -webkit-backface-visibility: hidden;
            }

            .imageContainer {
                @include hardwareImage;

                border: 2px solid;
                @include mixins.theme('border-color', 'ui-secondary-color');
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                background: #f2f1f0;

                img.product-image,
                div.product-image-failed {
                    min-height: 140px;
                }

                img {
                    object-fit: cover;
                    border-radius: 19px 19px 0 0;
                }

                img:before,
                div.product-image-failed {
                    border-radius: 20px 20px 0 0;
                }
            }

            .imageContainer.favourite-container {
                padding: 15px;
                img {
                    object-fit: contain;
                }
            }

            .categoryName {
                @include mixins.theme('background', 'ui-secondary-color');
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                font-weight: 700;
                text-align: center;
                color: white;
                padding: 7px 0;
                font-size: 0.85em;
            }
        }

        .hardwareCategory.no-image {
            .imageContainer {
                display: none;
            }

            .categoryName {
                height: 184px;
                border-radius: 20px;
                font-size: 1.4em;
                font-weight: bold;
                line-height: 20px;
                padding-top: 60px;
            }
        }
    }

    @mixin numberSpinner {
        width: 75px;
        align-items: center;

        label {
            font-size: 0.75em;
            font-weight: 600;
            @include mixins.theme('color', 'ui-primary-color');
            margin-right: 5px;
            margin-bottom: 0;
        }

        input {
            margin: 0;
            padding: 0 4px;
            height: 30px;
            border: 2px solid;
            @include mixins.theme('border-color', 'ui-primary-color');
            border-radius: 5px 0 0 5px;
            font-size: 12px;
            font-weight: 500;
        }

        .spinner {
            div {
                border: 2px solid;
                @include mixins.theme('border-color', 'ui-primary-color');
                height: 15px;
                border-left: 0;
                font-size: 12px;
                line-height: 8px;
                color: gray;
                padding: 0 2px;
                cursor: pointer;
                user-select: none;
            }

            div:first-child {
                border-bottom: 0;
                border-top-right-radius: 5px;
            }
            div:last-child {
                border-top: 0;
                border-bottom-right-radius: 5px;
            }
        }
    }

    .hardwareItems {
        margin-top: 15px;
        margin-bottom: 15px;

        $animation-duration: 6s;

        @keyframes placeHolderShimmer {
            0% {
                background-position: 0px 0;
            }

            100% {
                background-position: 100em 0;
            }
        }

        .animated-background {
            border-radius: 20px;
            margin: 0;
            width: 200px;
            height: 133px;
            animation-duration: $animation-duration;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: placeHolderShimmer;
            animation-timing-function: linear;
            background: #fff;
            background: linear-gradient(
                to right,
                #f5f5f5 8%,
                /* lighter grey color */ #eeeeee 18%,
                /* lighter grey color */ #f5f5f5 33% /* lighter grey color */
            );
            -webkit-backface-visibility: hidden;
        }

        .itemFavourite {
            background: url('assets/images/Favourite-item.png') no-repeat;
            content: '';
            width: 22px;
            height: 27px;
            top: 16px;
            right: 16px;
            z-index: 2;
            position: absolute;
            background-size: contain;
            border: 0;
            margin: 0;
            padding: 0;
            z-index: 25;
            @media screen and (min-width: 768px) {
                &:hover {
                    background-image: url('assets/images/Favourite-item-selected.png');
                    z-index: 25;
                }
            }
            &.userFavourite {
                background-image: url('assets/images/Favourite-item-selected.png');
                z-index: 25;
            }
        }

        .hardwareItem:hover {
            .details {
                @include mixins.theme('border-color', 'ui-secondary-color');
            }

            .cartButtons .numberSpinner {
                display: flex;
            }
        }

        .hardwareItem {
            text-align: center;
            z-index: 10;

            $animation-duration: 6s;

            @keyframes placeHolderShimmer {
                0% {
                    background-position: 0px 0;
                }

                100% {
                    background-position: 100em 0;
                }
            }

            .animated-background {
                border-radius: 20px;
                margin: 0;
                width: 200px;
                height: 133px;
                animation-duration: $animation-duration;
                animation-fill-mode: forwards;
                animation-iteration-count: infinite;
                animation-name: placeHolderShimmer;
                animation-timing-function: linear;
                background: #fff;
                background: linear-gradient(
                    to right,
                    #f5f5f5 8%,
                    /* lighter grey color */ #eeeeee 18%,
                    /* lighter grey color */ #f5f5f5 33%
                        /* lighter grey color */
                );
                -webkit-backface-visibility: hidden;
                z-index: 10;
                position: relative;
            }

            .details {
                position: relative;
                padding: 15px 15px 0;
                border: 3px solid white;
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                min-height: 200px;
                align-items: center;

                &.no-padding {
                    padding: 0;
                }

                .item-name {
                    margin-bottom: 5px;
                    z-index: 3;
                }
            }
            .details.hardware-details {
                min-height: 265px;
            }

            .details:hover {
                text-decoration: none !important;
            }

            .imageContainer {
                @include hardwareImage;
                flex: 1;
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: center;

                img.product-image,
                div.product-image-failed {
                    min-height: 100px;
                }

                div {
                    z-index: 1;
                    width: auto;
                }

                img:before,
                div.product-image-failed {
                    border-radius: 8px;
                }

                .animated-background-for-image {
                    z-index: 20;
                    border-radius: 20px;
                    border-top-right-radius: 0;
                    margin: 0;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    color: #6b6f70;
                    text-align: center;
                    height: 100px;
                    animation-duration: $animation-duration;
                    animation-fill-mode: forwards;
                    animation-iteration-count: infinite;
                    animation-name: placeHolderShimmer;
                    animation-timing-function: linear;
                    background: #fff;
                    background: linear-gradient(
                        to right,
                        #f5f5f5 8%,
                        /* lighter grey color */ #eeeeee 18%,
                        /* lighter grey color */ #f5f5f5 33%
                            /* lighter grey color */
                    );
                    -webkit-backface-visibility: hidden;
                }
            }

            > a {
                margin: 10px 0 0;
                color: #57585a;
                font-weight: 400;
                font-size: 0.85em;
            }

            .cartButtons {
                margin-top: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 3;

                .numberSpinner {
                    @include numberSpinner;

                    display: none;
                }
            }

            .button-blue {
                line-height: 0;
                padding: 0.1em 0.5em 0.1em 0.1em !important;
                margin-right: 5px;
                height: 30px;

                span {
                    font-weight: 500;
                    font-size: 0.6em;
                }

                img {
                    width: 22px;
                }
            }
        }
    }

    .hardware-product-details {
        h1 {
            background: white;
            @include mixins.theme('color', 'ui-primary-color');
            font-weight: 800;
            margin: 0 0 15px 0;
            padding: 0;
            border-radius: 0;

            span {
                font-weight: 600;
            }
        }

        .price {
            @include mixins.theme('color', 'ui-secondary-color');
            font-size: 0.85em;

            strong {
                font-size: 1.5em;
                font-weight: 800;
            }
        }

        h2 {
            background: white;
            @include mixins.theme('color', 'ui-primary-color');
            text-align: left;
            padding: 0;
        }

        .numberSpinner {
            @include numberSpinner;
            display: flex;
            width: fit-content;

            label {
                font-size: 0.85em;
                font-weight: bold;

                i {
                    color: red;
                }
            }

            input {
                width: 40px;
            }
        }

        .details {
            font-size: 0.85em;
            color: #8a8a8a;

            ul {
                list-style-position: inside;
                padding: 0;
            }
        }
    }

    .hardware-item-buttons {
        margin: 50px 0 30px;

        .item-button {
            width: 100%;
        }

        div:first-child {
            text-align: right;
        }
    }

    .product-images {
        position: relative;

        .imageContainer:not(.productImageContainer) {
            @include hardwareImage;
            height: 320px;

            img {
                box-shadow: inset 0 0 2px 2px white;
            }

            img:before {
                padding-top: 30%;
            }
        }

        .itemFavourite {
            background-image: url('assets/images/Favourite-item.png');
            content: '';
            width: 22px;
            height: 27px;
            top: 1px;
            right: 16px;
            z-index: 25;
            position: absolute;
            background-size: contain;
            border: 0;
            margin: 0;
            padding: 0;
            @media screen and (min-width: 768px) {
                &:hover {
                    background-image: url('assets/images/Favourite-item-selected.png');
                    z-index: 25;
                }
            }
            &.userFavourite {
                background-image: url('assets/images/Favourite-item-selected.png');
                z-index: 25;
            }
        }

        .itemFavouriteInSizesTab {
            background-image: url('assets/images/Favourite-item.png');
            content: '';
            width: 22px;
            height: 27px;
            top: 1px;
            right: 0px;
            left: 7.5rem;
            z-index: 25;
            position: absolute;
            background-size: contain;
            border: 0;
            margin: 0;
            padding: 0;
            &.leftInherit {
                left: inherit;
            }
            &.leftReduced {
                left: 6rem;
            }
            @media screen and (min-width: 768px) {
                &:hover {
                    background-image: url('assets/images/Favourite-item-selected.png');
                    z-index: 25;
                }
            }
            &.userFavourite {
                background-image: url('assets/images/Favourite-item-selected.png');
                z-index: 25;
            }
            &.btn:focus {
                box-shadow: none;
            }
        }
    }
}

@media (max-width: 695px) {
    .hardwareItems.row .col-4,
    .hardwareItems.row .col-6 {
        padding-right: 5px;
        padding-left: 5px;
    }

    .hardware .hardware-content {
        padding: 0;
    }

    .hardware .productSearch,
    .Products .productSearch {
        flex-direction: column;
        align-items: flex-start;

        .productSearchField {
            width: 100%;
        }
    }

    .product-details {
        margin-top: 10px;
    }

    .hardware-item-buttons {
        > div {
            padding: 0;
        }

        .button-blue {
            margin-bottom: 10px;
        }
    }
}
